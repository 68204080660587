<template>
  <div class="div-containing-buttons">
    <details class="details-toggle">
      <summary class="clickable-summary">
        <i class="chevron fas fa-chevron-right"></i>
        <i :class="`fas ${selectedSportIcon}`"></i>
        <span>{{ selectedSportName }}</span>
      </summary>
      <div class="details-content">
        <button v-for="sport in sports" :key="sport.id" class="clickable-button"
          :class="{ 'selected-sport': selectedSport === sport.id }" @click="selectSport(sport.id)">
          <i :class="`fa-solid ${sport.icon}`"></i> {{ sport.name }}
        </button>
        <button class="clickable-button" @click="showCustomMatchupContainer"><i class="fas fa-binoculars"></i> Custom
          Search</button>
      </div>
    </details>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import axios from 'axios';
import { useStore } from "../stores/store";
import { useGtm } from "@gtm-support/vue-gtm";
import { useMapsStore } from "../stores/maps";

const gtm = useGtm();
const trackEvent = gtm.trackEvent.bind(gtm);
const store = useStore();
const mapsStore = useMapsStore();
function showCustomMatchupContainer() {
  store.toggleCustomMatchupVisibility();
  const detailsElement = document.querySelector('.details-toggle');
  if (detailsElement) {
    detailsElement.removeAttribute('open');
  }
}

const sports = mapsStore.getSportIdNameIcon; 

const selectedSport = computed({
  get: () => store.selectedSport,
  set: (value) => store.setSelectedSport(value)
});

const selectedSportIcon = computed(() => {
  return sports.find(sport => sport.id === store.selectedSport)?.icon || "fa-basketball-ball";
});

const selectedSportName = computed(() => {
  return sports.find(sport => sport.id === store.selectedSport)?.name || "Select Sport";
});

// Adapted sportOddsFetch function from Games.vue
async function sportOddsFetch(sport: string, track: boolean = true) {
  try {
    if (track) {
      trackEvent({
        event: "sport_odds_fetch", // This could serve as the Event Action
        category: "Odds Fetching", // Event Category
        action: "Fetch Sport Odds", // More specific action
        label: sport, // Use the sport as the label for specificity
        value: 1, // Optional: Quantify the event, e.g., number of fetches
        noninteraction: false, // Indicates whether this event is a non-interaction event
      });
    }
    const response = await axios.get(`https://back.betboss.ai/get_sport_odds?sport_name=${sport}`);
    // Assuming you have a method or a way to update the odds in your store or local state
    store.updateSportOdds(response.data); // Adapt this line to match how your store updates odds
  } catch (error) {
    console.error(error);
  }
}

function selectSport(sportId) {
  store.selectedSport = sportId;
  const sport = sports.find(s => s.id === sportId);
  if (sport) {
    sportOddsFetch(sport.id);
  }
  // Close the dropdown by removing the 'open' attribute
  const detailsElement = document.querySelector('.details-toggle');
  if (detailsElement) {
    detailsElement.removeAttribute('open');
  }
}


onMounted(() => {
  const defaultSportId = store.selectedSport;
  sportOddsFetch(defaultSportId, false);
});

</script>

<style scoped>
@import "@/assets/games.css";

.selected-sport {
  background-color: #13c823;
  color: white;
  outline: 2px solid #e9e9e9;
  outline-offset: -2px;
}

.details-toggle,
.custom-search-button {
  flex-basis: 100%;
  border: 2px solid #1e5128;
  border-radius: 12px;
  background-color: #4e9f3d;
  color: #d0e2cd;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.details-toggle:hover,
.custom-search-button:hover {
  background-color: #1e5128;
  color: #ffffff;
  border-color: #d0e2cd;
  box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.3);
}

.clickable-summary {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
  user-select: none;
  gap: 5px;
}

.details-content {
  background-color: #222;
  color: #fff;
  border: 1px solid #444;
  border-radius: 4px;
  padding: 10px;
  margin-top: 10px;
}

.details-content button {
  text-align: left;
}

.clickable-summary .chevron {
  margin-right: 10px;
  transition: transform 0.2s ease;
}

.details-toggle[open] .clickable-summary .chevron {
  transform: rotate(90deg);
}

.details-toggle {
  flex-basis: 100%;
  line-height: 0;
}
</style>
