<template>
  <div>
    <TopBar />
    <div class="stripe-buttons-grid">
      <stripe-buy-button v-for="button in stripeButtons" :key="button.id" :buy-button-id="button.id"
        :publishable-key="publishableKey" :client-reference-id="clientReferenceId">
      </stripe-buy-button>
    </div>
    <Footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import TopBar from "./TopBar.vue";
import Footer from "./Footer.vue";

const clientReferenceId = ref('');
const publishableKey = "pk_live_51OlbxxCwPAlJePWckrBDCzu7rNypjkdmITTtwNz7z2Y2fTav6i8TXAYOtzECVMPaODKjbRNDU00O6VvtOlzwfPQN00KVmzRTmO";
const stripeButtons = [
  // { id: "buy_btn_1PZHXJCwPAlJePWcDLURBATb" },
  { id: "buy_btn_1PYtvvCwPAlJePWclTe1MR2E" },
  { id: "buy_btn_1PYtyfCwPAlJePWc9csHOkOw" },
  { id: "buy_btn_1PYtOLCwPAlJePWc6CmOGdtY" },
];

// function getCookie(name: string): string | null {
//   const nameEQ = name + "=";
//   const ca = document.cookie.split(';');
//   for (let i = 0; i < ca.length; i++) {
//     let c = ca[i];
//     while (c.charAt(0) === ' ') c = c.substring(1, c.length);
//     if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
//   }
//   return null;
// }
// function parseTrackdeskCookie(cookieValue: string): string | null {
//   // Remove surrounding quotes if present
//   cookieValue = cookieValue.replace(/^"|"$/g, '');
  
//   // Try parsing as JSON
//   try {
//     const parsedValue = JSON.parse(cookieValue);
//     return parsedValue.cid || null;
//   } catch (e) {
//     // If JSON parsing fails, try to extract cid directly
//     const match = cookieValue.match(/cid[":]\s*["']?([^"',}]+)/);
//     return match ? match[1] : null;
//   }
// }

onMounted(() => {
  // Load Stripe script
  const stripeScript = document.createElement('script');
  stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
  stripeScript.async = true;
  document.head.appendChild(stripeScript);

  // Get client reference ID from Trackdesk cookie
  const cookie = document.cookie.match('(^|;)\\s*trakdesk_cid\\s*=\\s*([^;]+)');
  if (Array.isArray(cookie)) {
    try {
      const trakdeskCid = JSON.parse(cookie.pop());
      const cid = trakdeskCid['cid'];
      clientReferenceId.value = cid;
      console.log('Client Reference ID:', clientReferenceId.value);
    } catch (e) {
      console.error('Error parsing trakdeskCid:', e);
    }
  }
});
</script>

<style scoped>
.stripe-buttons-grid {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  padding: 1rem;
  flex-wrap: nowrap;
}

:deep(.stripe-buy-button) {
  flex: 1;
  min-width: 0;
}

:deep(.stripe-buy-button iframe) {
  width: 100% !important;
  min-width: 0 !important;
}

@media (max-width: 768px) {
  .stripe-buttons-grid {
    flex-direction: column;
  }
}
</style>

