<template>
    <div class="custom-matchup-container" :style="customMatchupContainerStyle">
        <div class="input-button-row">
            <input v-model="gameMatchupCustom" placeholder="Game Matchup e.g., Bulls vs Heat"
                @keyup.enter="customMatchupAnalyze" />
            <button class="clickable-button" @click="customMatchupAnalyze">
                <span v-if="customLoading" class="loading-indicator" style="white-space: nowrap"><i
                        class="fas fa-spinner fa-spin"></i> LOADING...</span>
                <span v-else>Analyze Game</span>
            </button>
        </div>
        <details v-if="customResponse" open>
            <summary style="cursor: pointer; transition: all 0.25s ease">
                <div>Custom Matchup Analysis</div>
            </summary>
            <div class="llm-response">{{ customResponse }}</div>
        </details>
    </div>
</template>

<script setup lang="ts">
import { ref, defineEmits, computed } from 'vue';

// Define reactive properties
const gameMatchupCustom = ref("");
const customResponse = ref(null);
const customLoading = ref(false);
import { useStore } from "../stores/store";

const store = useStore();

const isCustomMatchupVisible = computed(() => store.isCustomMatchupVisible);

const customMatchupContainerStyle = computed(() => ({
    display: isCustomMatchupVisible.value ? 'block' : 'none',
}));

const emit = defineEmits(['fetch-strategy']);
function customMatchupAnalyze() {
    if (gameMatchupCustom.value.trim() !== "") {
        emit('fetch-strategy', gameMatchupCustom.value);
    }
}
</script>

<style scoped>
@import "@/assets/games.css";

.input-button-row input {
    cursor: text;
}
</style>