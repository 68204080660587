import { defineStore } from "pinia";
import axios from "axios";

export const useStore = defineStore("store", {
  state: () => ({
    searchCount: 0,
    userTier: "",
    streamLogs: {},
    eventSource: null, 
    sportOdds: {}, 
    selectedSport: 'baseball_mlb',
    isCustomMatchupVisible: false,
    userEmail: "",
  }),

  getters: {
    // Keep the allowedSearches getter as is
    allowedSearches: (state) => {
      switch (state.userTier) {
        case "rookie":
          return 100;
        case "pro":
          return 300;
        case "free":
          return 10;
        default:
          return 3; 
      }
    }, 
    // Adjust the searchesLeftForUserTier getter to not use `this`
    searchesLeftForUserTier: (state) => {
      // Directly compute allowed searches within this getter
      let allowedSearches;
      switch (state.userTier) {
        case "rookie":
          allowedSearches = 100;
          break;
        case "pro":
          allowedSearches = 300;
          break;
        case "free":
          allowedSearches = 10;
          break;
        default:
          allowedSearches = 3; 
      }
      return allowedSearches - state.searchCount;
    },
// New getter for mapping selectedSport to a user-friendly name
    sportDisplayName: (state) => {
      const sportMap = { baseball_mlb: "MLB",
        icehockey_nhl: "NHL",
        basketball_nba: "NBA",
        soccer_uefa_european_championship: "UEFA Cup",
        football_nfl: "NFL",
        soccer_uefa_nations_league: "UEFA Nations",
        // Add more mappings as needed
      };
      return sportMap[state.selectedSport] || state.selectedSport; // Fallback to the original value if no mapping found
    },
  },

  actions: {
    setSelectedSport(sportId) {
      this.selectedSport = sportId;
    },
    toggleCustomMatchupVisibility() {
      this.isCustomMatchupVisible = !this.isCustomMatchupVisible;
    },
    async updateUserTierFromServer() {
      if (this.userTier) {
        // If userTier is already set, no need to fetch it again
        return;
      }
      console.log("Fetching user tier and calculating remaining searches...");
      try {
        const response = await axios.get("https://back.betboss.ai/check-login");
        if (response.data.logged_in && response.data.user.fields.tier) {
          this.userTier = response.data.user.fields.tier;
        } else {
          this.userTier = "free"; // Set default to 'free' if not found
        }
      } catch (error) {
        console.error("Error fetching user tier:", error);
        this.userTier = "free"; // Set default to 'free' in case of an error
      }
    },

    // addStreamLog({ gameId, message }) {
    //   console.log(`Adding stream log for game ID: ${gameId} with message: ${message}`); // Debugging log
    //   if (!this.streamLogs[gameId]) {
    //     this.streamLogs[gameId] = []; // Initialize an array if it doesn't exist
    //     console.log(`Stream log array initialized for game ID: ${gameId}`); // Debugging log
    //   }
    //   this.streamLogs[gameId].push(message); // Add the message to the array for the gameId
    //   console.log(`Message added to stream log for game ID: ${gameId}`); // Debugging log
    // },

    // initStream() {
    //   if (this.eventSource) {
    //     console.log("Stream already initialized.");
    //     return;
    //   }

    //   // Change to WebSocket initialization
    //   console.log("Initializing WebSocket stream..."); // Debugging log
    //   this.eventSource = new WebSocket("wss://back.betboss.ai/ws/logs");

    //   this.eventSource.onmessage = (event) => {
    //     console.log("WebSocket message event received."); // Debugging log
    //     const logEntry = JSON.parse(event.data);
    //     console.log("New stream message received:", logEntry); // Log the new message to the console
    //     this.addStreamLog(logEntry); // Use existing action to add log entry
    //   };

    //   this.eventSource.onopen = () => {
    //     console.log("WebSocket connection established.");
    //   };

    //   this.eventSource.onerror = (error) => {
    //     console.error("WebSocket error:", error);
    //     this.eventSource.close();
    //     console.log("WebSocket connection closed due to error."); // Debugging log
    //     this.eventSource = null; // Reset the eventSource on error
    //   };

    //   console.log("WebSocket stream initialized.");
    // },

    // // Close the WebSocket connection
    // closeStream() {
    //   if (this.eventSource) {
    //     console.log("Closing WebSocket stream..."); // Debugging log
    //     this.eventSource.close();
    //     this.eventSource = null;
    //     console.log("WebSocket stream closed.");
    //   }
    // },

    updateSportOdds(newOdds) {
      console.log("Updating sport odds in the store...");
      this.sportOdds = newOdds;
    },
    
  },

  persist: true,
});
